import React from "react";
import Booking from "@herohealthsoftware/booking-react";
import { Spinner, ThemeProvider } from "@herohealthsoftware/ui";
import * as Routes from "../../routes";

const LinkBuilder = ({
  env,
  jwtToken,
  practiceGroupId,
  patientId,
  locationId,
  practitionerId,
  stripeBooking,
  rescheduleAppointmentId,
  reschedulePracitceGroupId,
  apiKey,
  adminId,
  adminHasCustomTimes,
}) => {
  const sharedProps = {
    env,
    tenantId: practiceGroupId,
    patientId: patientId?.toString() || null,
    practiceGroupId: null,
    locationId: locationId?.toString() || null,
    appointmentTypeId: null,
    practitionerId: practitionerId?.toString() || null,
    reschedulePracitceGroupId: reschedulePracitceGroupId?.toString() || null,
    rescheduleAppointmentId: rescheduleAppointmentId?.toString() || null,
    features: {
      reserveOnlyUnlessInvoiceSuppressed: stripeBooking,
      crossOrg: !stripeBooking,
      customTimes: adminHasCustomTimes,
    },
    onReserve:
      stripeBooking
        ? (appointment) => {
            window.location.replace(
              `/partners/stripe/admins/invoices/new?appointment_id=${appointment.appointment_id}`
            );
          }
        : undefined,
    onBook: (booking) => {
      window.location.href = Routes.admin_appointment_path({
        id: booking.appointment_id,
      });
    },
    onReschedule: (reschedule) => {
      window.location.href = Routes.admin_appointment_path({
        id: reschedule.appointment_id,
      });
    },
    loader: (
      <ThemeProvider>
        <Spinner className="h-12 w-12" />
      </ThemeProvider>
    ),
  };

  return (
    <>
      {jwtToken ? (
        <Booking jwtToken={jwtToken} {...sharedProps} />
      ) : (
        <Booking apiKey={apiKey} adminId={adminId} {...sharedProps} />
      )}
    </>
  );
};

export default LinkBuilder;
