import * as Routes from "../routes";
import { formatISODateTime } from "./datetime";
import { formatPence, toPounds } from "./money";
import { InvoiceItem, Product, Subscription } from "./types";

export type StripeSearchType = "invoice" | "product" | "customer" | "coupon";

export type StripeSearchFilters = Record<string, string>;

export type StripeSearchOptions = {
  cursor?: string;
  filters?: StripeSearchFilters;
};

export async function searchStripe<T>(
  query?: string,
  options?: StripeSearchOptions & {
    type?: StripeSearchType;
  }
): Promise<{ data: T[]; cursor: string }> {
  const url = Routes.partners_stripe_admins_search_path({
    query,
    ...options,
  });

  const response = await fetch(url);
  const json = await response.json();

  return json;
}

export function invoiceItemFieldsFromProduct(
  product: Product
): Pick<
  InvoiceItem,
  "product" | "description" | "unit_amount_in_pounds" | "price" | "prices"
> {
  const default_price = product.default_price || product.prices[0];

  return {
    product,
    description: product.name || "",
    unit_amount_in_pounds: toPounds(default_price.unit_amount),
    price: default_price.id,
    prices: product.prices,
  };
}

export function formatBillingPeriodForSubscription(
  subscription: Subscription
): string | null {
  if (
    !subscription?.current_period_end_on ||
    !subscription?.current_period_start_on
  ) {
    return null;
  }

  const start_on = formatISODateTime(
    subscription.current_period_start_on,
    "short-month-name-date"
  );
  const end_on = formatISODateTime(
    subscription.current_period_end_on,
    "short-month-name-date"
  );

  return `${start_on} to ${end_on}`;
}

export function formatIntervalForSubscripiton(
  subscription: Subscription
): string {
  if (!subscription?.plan) {
    return null;
  }

  const price = formatPence(subscription.plan.amount);

  if (subscription.plan.interval_count === 1) {
    return `${price} every ${subscription.plan.interval}`;
  }

  return `${price} every ${subscription.plan.interval_count} ${subscription.plan.interval}s`;
}
