import React from "react";
import HeroHealthMessageBuilder from "@herohealthsoftware/message-builder-react";
import { Spinner, ThemeProvider } from "@herohealthsoftware/ui";

const MessageBuilderPage = ({
  env,
  apiKey,
  practiceGroupId,
  patientId,
  adminId,
  taskId,
  jwtToken,
}) => {
  const sharedProps = {
    env,
    tenantId: practiceGroupId,
    patientId,
    taskId,
    telemetry: true,
    loader: (
      <ThemeProvider>
        <Spinner className="h-12 w-12" />
      </ThemeProvider>
    ),
  };
  return (
    <>
      {jwtToken ? (
        <HeroHealthMessageBuilder jwtToken={jwtToken} {...sharedProps} />
      ) : (
        <HeroHealthMessageBuilder
          apiKey={apiKey}
          adminId={adminId}
          {...sharedProps}
        />
      )}
    </>
  );
};

export default MessageBuilderPage;
